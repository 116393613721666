<template>
  <div class="p-grid">
    <div style="width:20rem; margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px; background: #fff">
      <div style="padding-right: 1.5rem;padding-top: 1.5rem;padding-left: 1.5rem;">
        <h3 class="p-m-0" style="line-height: .9">
          <strong>{{ _t('menu.private_media_schedules') }}</strong>
        </h3>
        <i style="font-size: 12px;">{{ _t('label_prev_Ind_calendar') }}</i>
        <br>
        <MultiSelectableBreadcrumb
            ref="breadcrumbData"
            :tree-params="treeParams"
            single-selection
            @change="setOrgId($event)"
        />
      </div>
      <span class="p-input-icon-right p-m-3" style="width: calc(100% - 3rem)">
          <i class="pi pi-search" style="font-size: 12px;"/>
          <InputText v-model="filterValue" style="width:100%; margin: 5px;" type="text"/>
        </span>

      <DataTable
          ref="tree"
          v-model:selection="selectedParticipantItem"
          :dataKey="tableDataKey"
          :lazy="true"
          :page="currentPage"
          :paginator="true"
          :paginatorTemplate="'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'"
          :rowHover="true"
          :rows="tableRows"
          :scrollable="true"
          :totalRecords="getCount"
          :value="getParticipants"
          class="tight"
          scrollHeight="75vh"
          selectionMode="single"
          stripedRows
          @page="selectTablePage($event)"
          @row-click="changeParticipant($event.data.id)"
      >
        <Column :expander="true"
                field="name"
                header="Name"
                style="width: 100%; word-break: break-all;min-height: 38px;"
        >
          <template #body="{data}">
                <span class="wrap"
                      style="word-break: break-all; padding-left: 5px;">
              {{ data.name }}
               </span>
          </template>
        </Column>
      </DataTable>
    </div>
    <transition name="slide-fade">
      <div class="p-col">
        <Card v-if="getCalendar">
          <template #content>
            <CalendarConfigurator
                v-if="getCalendar"
                :orgId="orgId"
                :participantId="selectedParticipantItem ? selectedParticipantItem.id : null"
                :participantName="(selectedParticipantItem.surname + ' ' + selectedParticipantItem.name) + (selectedParticipantItem.login ? ' (' + selectedParticipantItem.login + ')' : '' )"
            />
          </template>
        </Card>
      </div>
    </transition>
  </div>
</template>

<script>
import MultiSelectableBreadcrumb from '@/components/ixarma/MultiSelectableBreadcrumb';
import CalendarConfigurator from './calendar-configurator';
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  components: {MultiSelectableBreadcrumb, CalendarConfigurator},
  data: () => ({
    treeParams: null,
    selectedParticipantItem: null,
    orgId: localStorage.getItem('orgId') ?? 1,
    tableRows: 100,
    currentPage: 0,
    filterValue: '',
    tableDataKey: null
  }),
  mounted() {
    this.checkOrgId()
  },
  methods: {
    ...mapActions({
      'callCalendar': 'individualCalendar/callCalendar',
      'callParticipants': 'participant/getParticipants',
    }),
    ...mapMutations({
      'setCalendar': 'individualCalendar/setCalendar',
      'setEvents': 'individualCalendar/setEvents',
      'setParticipants': 'participant/setParticipants',
      'setSearch': 'participant/setSearch'
    }),
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.setOrgId()
        }
      }, 2000);
    },
    selectTablePage(item) {
      this.loading = true
      this.currentPage = item.page
      this.setParticipants({
        orgId: this.orgId,
        filterValue: this.filterValue,
        currentPage: this.currentPage,
        pageSize: this.tableRows
      })
      this.callParticipants(this.orgId, true);
    },
    setOrgId() {
      this.setParticipants({
        orgId: this.orgId,
        filterValue: this.filterValue,
        currentPage: this.currentPage,
        pageSize: this.tableRows
      })
      this.callParticipants(this.orgId, true);
    },
    changeParticipant(id) {
      if (id) {
        this.callCalendar(id);

      }
    }
  },
  watch: {
    filterValue() {
      this.setSearch(this.filterValue)
      this.callParticipants(this.orgId, true);
    }
  },
  computed: {
    ...mapGetters({
      'getCalendar': 'individualCalendar/getCalendar',
      'getEvents': 'individualCalendar/getEvents',
      'getParticipants': 'participant/getParticipants',
      'getCount': 'participant/getCount',
    }),
  },
}
</script>
<style lang="scss" scoped>
::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
    background-color: #f8f9fa;
    padding-left: 10px !important;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 5px;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
